import React from "react";
import {
    Datagrid,
    List,
    TextField,
    useLocaleState
} from "react-admin";

const CategoryList = () => {
    const [locale] = useLocaleState();
    
    return (
        <List>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source={`name.${locale}`} />
            </Datagrid>
        </List>
    );
};

export default CategoryList;