import { RichTextInput } from "ra-input-rich-text";
import * as React from "react";
import { SimpleForm, TranslatableInputs } from "react-admin";
import { EditConfig } from "../../common/editConfig/EditConfig";
import classes from "./AboutPageConfigEdit.module.css";

const AboutPageConfigEdit = (props) => {
  return (
    <>
      <EditConfig resource="about-page">
        <SimpleForm style={{ display: "flex" }}>
          <TranslatableInputs
            locales={["en", "ru"]}
            className={classes.TranslatableInputs}
          >
            <RichTextInput fullWidth source="description" />
          </TranslatableInputs>
        </SimpleForm>
      </EditConfig>
    </>
  );
};

export default AboutPageConfigEdit;
