import * as React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    TranslatableInputs
} from "react-admin";

const CategoryAdd = (props) => {
    return (
        <Create {...props}>
            <SimpleForm style={{ display: "flex", width: "100%" }}>
                <TranslatableInputs className="fullWidth" locales={["en", "ru"]}>
                    <TextInput source="name" fullWidth />
                </TranslatableInputs>
            </SimpleForm>
        </Create>
    );
};

export default CategoryAdd;