import * as React from "react";
import {
  Edit,
  PasswordInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

const UserEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm style={{ display: "flex" }}>
        <TextInput fullWidth source="email" />
        <SelectInput
          fullWidth
          source="role"
          choices={[{ id: "ROLE_ADMINISTRATOR", name: "Administrator" }]}
        />
        <PasswordInput fullWidth source="password" />
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
