import * as React from "react";
import {
  Create,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput
} from "react-admin";

const ContactsAdd = (props) => {
  return (
    <Create {...props} mutationOptions={{ meta: { fileUpload: true } }}>
      <SimpleForm style={{ display: "flex" }}>
        <ImageInput source="picture" label="Preview">
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput fullWidth source="name" />
        <TextInput fullWidth source="url" />
      </SimpleForm>
    </Create>
  );
};

export default ContactsAdd;
