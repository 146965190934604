import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import * as React from "react";
import {
  Empty,
  Error,
  ListActions,
  ListBase,
  ListClasses,
  ListToolbar,
  Pagination,
  useListContext
} from "react-admin";

const defaultActions = <ListActions />;
const defaultPagination = <Pagination />;
const defaultEmpty = <Empty />;
const DefaultComponent = Card;

export const ListView = (props) => {
  const {
    actions = defaultActions,
    aside,
    filters,
    bulkActionButtons,
    emptyWhileLoading,
    hasCreate,
    pagination = defaultPagination,
    children,
    className,
    component: Content = DefaultComponent,
    title,
    empty = defaultEmpty,
    ...rest
  } = props;
  const { data, error, total, isLoading, filterValues } = useListContext(props);

  if (!children || (!data && isLoading && emptyWhileLoading)) {
    return null;
  }

  const renderList = () => (
    <div className={ListClasses.main}>
      {(filters || actions) && (
        <ListToolbar
          filters={filters}
          actions={actions}
          hasCreate={hasCreate}
        />
      )}
      <Content className={ListClasses.content}>
        {bulkActionButtons && children
          ? React.cloneElement(React.Children.only(children), {
              bulkActionButtons,
            })
          : children}
      </Content>
      {error ? (
        // @ts-ignore
        <Error error={error} resetErrorBoundary={null} />
      ) : (
        pagination !== false && pagination
      )}
    </div>
  );

  const renderEmpty = () =>
    empty !== false && React.cloneElement(empty, { hasCreate });

  const shouldRenderEmptyPage =
    !isLoading &&
    total === 0 &&
    !Object.keys(filterValues).length &&
    empty !== false;

  return (
    <Root
      className={clsx("list-page", className)}
      style={{ width: "100%" }}
      {...rest}
    >
      {shouldRenderEmptyPage ? renderEmpty() : renderList()}
      {aside}
    </Root>
  );
};

export const ListWithoutTitle = (props) => {
  const {
    debounce,
    disableAuthentication,
    disableSyncWithLocation,
    exporter,
    filter,
    filterDefaultValues,
    perPage,
    queryOptions,
    resource,
    sort,
    storeKey,
    ...rest
  } = props;
  return (
    <ListBase
      debounce={debounce}
      disableAuthentication={disableAuthentication}
      disableSyncWithLocation={disableSyncWithLocation}
      exporter={exporter}
      filter={filter}
      filterDefaultValues={filterDefaultValues}
      perPage={perPage}
      queryOptions={queryOptions}
      resource={resource}
      sort={sort}
      storeKey={storeKey}
    >
      <ListView {...rest} />
    </ListBase>
  );
};

const PREFIX = "RaList";

const Root = styled("div", {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: "flex",

  [`& .${ListClasses.main}`]: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  [`& .${ListClasses.content}`]: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
    },
    overflow: "inherit",
  },

  [`& .${ListClasses.actions}`]: {
    zIndex: 2,
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
  },

  [`& .${ListClasses.noResults}`]: { padding: 20 },
}));
