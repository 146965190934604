import Card from "@mui/material/Card";
import { RichTextInput } from "ra-input-rich-text";
import * as React from "react";
import {
  Datagrid,
  ImageField,
  Labeled,
  RichTextField,
  SimpleForm,
  TextField,
  TextInput,
  TranslatableInputs,
  UrlField,
  useLocaleState,
  useTranslate,
} from "react-admin";
import { EditConfig } from "../../common/editConfig/EditConfig";
import { ListWithoutTitle } from "../../common/ListWithoutTitle/ListWithoutTitle";
import classes from "./MainPageConfigEdit.module.css";

const MainPageConfigEdit = (props) => {
  const [locale] = useLocaleState();
  const translate = useTranslate();

  return (
    <Card style={{ marginTop: "1em" }}>
      <EditConfig resource="main-page" component="div">
        <SimpleForm style={{ display: "flex" }}>
          <TextInput fullWidth source="name" />
          <TranslatableInputs
            locales={["en", "ru"]}
            className={classes.TranslatableInputs}
          >
            <RichTextInput fullWidth source="description" />
          </TranslatableInputs>
          <Labeled
            label={translate("resources.advantages.name", { smart_count: 2 })}
            style={{ width: "100%", marginTop: "1em" }}
          >
            <ListWithoutTitle resource="advantages">
              <Datagrid rowClick="edit">
                <TextField source="id" />
                <ImageField source="picture.src" />
                <TextField source={`title.${locale}`} />
                <RichTextField source={`description.${locale}`} />
              </Datagrid>
            </ListWithoutTitle>
          </Labeled>
          <Labeled
            label={translate("resources.partners.name", { smart_count: 2 })}
            style={{ width: "100%", marginTop: "1em" }}
          >
            <ListWithoutTitle resource="partners">
              <Datagrid rowClick="edit">
                <TextField source="id" />
                <ImageField source="picture.src" />
                <TextField source={`name`} />
                <UrlField source={`url`} />
              </Datagrid>
            </ListWithoutTitle>
          </Labeled>
        </SimpleForm>
      </EditConfig>
    </Card>
  );
};

export default MainPageConfigEdit;
