import { Card } from "@mui/material";
import { RichTextInput } from "ra-input-rich-text";
import * as React from "react";
import {
  Datagrid,
  ImageField,
  Labeled, SimpleForm,
  TextField,
  TextInput,
  TranslatableInputs,
  UrlField,
  useTranslate
} from "react-admin";
import { EditConfig } from "../../common/editConfig/EditConfig";
import { ListWithoutTitle } from "../../common/ListWithoutTitle/ListWithoutTitle";
import classes from "./ContactsPageConfigEdit.module.css";

const ContactPageConfigEdit = (props) => {
  const translate = useTranslate();

  return (
    <>
      <Card style={{ marginTop: "1em" }}>
        <EditConfig resource="contacts-page" component="div">
          <SimpleForm style={{ display: "flex" }}>
            <TranslatableInputs
              locales={["en", "ru"]}
              className={classes.TranslatableInputs}
            >
              <TextInput fullWidth source="title" />
              <RichTextInput fullWidth source="description" />
            </TranslatableInputs>
            <Labeled
              label={translate("resources.advantages.name", { smart_count: 2 })}
              style={{ width: "100%", marginTop: "1em" }}
            >
              <ListWithoutTitle resource="contacts">
                <Datagrid rowClick="edit">
                  <TextField source="id" />
                  <ImageField source="picture.src" />
                  <TextField source={`name`} />
                  <UrlField source="url" />
                </Datagrid>
              </ListWithoutTitle>
            </Labeled>
          </SimpleForm>
        </EditConfig>
      </Card>
    </>
  );
};

export default ContactPageConfigEdit;
