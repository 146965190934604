import React from "react";
import { Datagrid, List, SelectField, TextField } from "react-admin";

const UsersList = () => {
  return (
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source={`email`} />
        <SelectField
          source="role"
          choices={[{ id: "ROLE_ADMINISTRATOR", name: "Administrator" }]}
        />
      </Datagrid>
    </List>
  );
};

export default UsersList;
