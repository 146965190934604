import * as React from "react";
import {
  Edit,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput
} from "react-admin";

const ContactsEdit = (props) => {
  return (
    <Edit {...props} redirect={'/contacts-page-setup'} mutationOptions={{ meta: { fileUpload: true } }}>
      <SimpleForm style={{ display: "flex" }}>
        <ImageInput source="picture" label="Preview">
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput fullWidth source="name" />
        <TextInput fullWidth source="url" />
      </SimpleForm>
    </Edit>
  );
};

export default ContactsEdit;