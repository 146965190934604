import { useDataProvider } from "react-admin";
import { useQuery } from "react-query";

const useGetConfig = (resource, { meta }, options) => {
  const dataProvider = useDataProvider();
    return useQuery(
        [resource, 'getConfig', { meta }],
        () =>
            dataProvider
                .getConfig(resource, { meta })
                .then(({ data }) => data),
        options
    );
};

export default useGetConfig;
