import { useCallback } from 'react';
import { useAuthenticated, useGetResourceLabel, useMutationMiddlewares, useNotify, useResourceContext } from 'react-admin';

import useGetConfig from './useGetConfig';
import useUpdateConfig from './useUpdateConfig';

/**
 * Prepare data for the Edit view.
 *
 * useEditController does a few things:
 * - it grabs the id from the URL and the resource name from the ResourceContext,
 * - it fetches the record via useGetOne,
 * - it prepares the page title.
 *
 * @param {Object} props The props passed to the Edit component.
 *
 * @return {Object} controllerProps Fetched data and callbacks for the Edit view
 *
 * @example
 *
 * import { useEditController } from 'react-admin';
 * import EditView from './EditView';
 *
 * const MyEdit = () => {
 *     const controllerProps = useEditController({ resource: 'posts', id: 123 });
 *     return <EditView {...controllerProps} {...props} />;
 * }
 */
export const useEditConfigController = (
    props = {}
) => {
    const {
        disableAuthentication,
        // @ts-ignore
        // @ts-ignore
        mutationMode = 'pessimistic',
        mutationOptions = {},
        queryOptions = {},
        transform,
    } = props;
    useAuthenticated({ enabled: !disableAuthentication });
    const resource = useResourceContext(props);
    const notify = useNotify();
    const { meta: queryMeta, ...otherQueryOptions } = queryOptions;
    const {
        onSuccess,
        onError,
        meta: mutationMeta,
        ...otherMutationOptions
    } = mutationOptions;
    const {
        registerMutationMiddleware,
        getMutateWithMiddlewares,
        unregisterMutationMiddleware,
    } = useMutationMiddlewares();
    const { data: record, error, isLoading, isFetching, refetch } = useGetConfig(
        resource,
        { meta: queryMeta },
        {
            onError: () => {
                notify('ra.notification.item_doesnt_exist', {
                    type: 'warning',
                });
                
            },
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: false,
            ...otherQueryOptions,
        }
    );

    const getResourceLabel = useGetResourceLabel();
    const defaultTitle = getResourceLabel(resource, 1);

    const recordCached = { previousData: record };

    // @ts-ignore
    const [update, { isLoading: saving }] = useUpdateConfig(resource, recordCached, { ...otherMutationOptions, mutationMode });

    const save = useCallback(
        (
            data,
            {
              // @ts-ignore
              onSuccess: onSuccessFromSave,
              // @ts-ignore
              onError: onErrorFromSave,
              // @ts-ignore
              transform: transformFromSave,
            } = {}
        ) =>
            Promise.resolve(
                // @ts-ignore
                transformFromSave
                    // @ts-ignore
                    ? transformFromSave(data, {
                          previousData: recordCached.previousData,
                      })
                    : transform
                    ? transform(data, {
                          previousData: recordCached.previousData,
                      })
                    : data
            // @ts-ignore
            ).then((data) => {
                // @ts-ignore
                const mutate = getMutateWithMiddlewares(update);
                return mutate(
                    resource,
                    // @ts-ignore
                    { data, meta: mutationMeta },
                    {
                        onSuccess: async (data, variables, context) => {
                            // @ts-ignore
                            if (onSuccessFromSave) {
                                // @ts-ignore
                                return onSuccessFromSave(
                                    data,
                                    variables,
                                    context
                                );
                            }

                            if (onSuccess) {
                                return onSuccess(data, variables, context);
                            }

                            notify('ra.notification.updated', {
                                type: 'info',
                                messageArgs: { smart_count: 1 },
                                undoable: mutationMode === 'undoable',
                            });
                        },
                        // @ts-ignore
                        onError: onErrorFromSave
                            // @ts-ignore
                            ? onErrorFromSave
                            : onError
                            ? onError
                            // @ts-ignore
                            : (error) => {
                                  notify(
                                      typeof error === 'string'
                                          ? error
                                          : error.message ||
                                                'ra.notification.http_error',
                                      {
                                          type: 'warning',
                                          messageArgs: {
                                              _:
                                                  typeof error === 'string'
                                                      ? error
                                                      : error && error.message
                                                      ? error.message
                                                      : undefined,
                                          },
                                      }
                                  );
                              },
                    }
                );
            }),
        [
            // @ts-ignore
            getMutateWithMiddlewares,
            mutationMeta,
            mutationMode,
            notify,
            onError,
            onSuccess,
            resource,
            transform,
            update,
            recordCached.previousData,
        ]
    );

    return {
        defaultTitle,
        error,
        isFetching,
        isLoading,
        mutationMode,
        record,
        redirect: false,
        refetch,
        registerMutationMiddleware,
        resource,
        save,
        saving,
        unregisterMutationMiddleware,
    };
};