import * as React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

const MessageFilterAdd = (props) => {
  return (
    <Create {...props}>
      <SimpleForm style={{ display: "flex", width: "100%" }}>
        <TextInput source="regex" fullWidth />
        <TextInput source="chatGptRequest" fullWidth />
      </SimpleForm>
    </Create>
  );
};

export default MessageFilterAdd;
