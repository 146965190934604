import { RichTextInput } from "ra-input-rich-text";
import * as React from "react";
import {
  AutocompleteArrayInput,
  Edit,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  TranslatableInputs,
  useLocaleState,
} from "react-admin";

const ProjectsEdit = (props) => {
  const [locale] = useLocaleState();

  return (
    <Edit {...props} mutationOptions={{ meta: { fileUpload: true } }}>
      <SimpleForm style={{ display: "flex" }}>
        <ImageInput source="picture" label="Preview">
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput fullWidth source="videoUrl" />
        <TranslatableInputs className="fullWidth" locales={["en", "ru"]}>
          <TextInput fullWidth source="title" />
          <RichTextInput fullWidth source="description" />
        </TranslatableInputs>
        <ReferenceArrayInput source="categories" reference="categories">
          <AutocompleteArrayInput
            fullWidth
            optionText={(value) => value.name[locale]}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default ProjectsEdit;
