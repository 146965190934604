import * as React from "react";
import {
    ArrayInput,
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

const ChatsConnectionAdd = (props) => {
  return (
    <Create {...props}>
      <SimpleForm style={{ display: "flex", width: "100%" }}>
        <ReferenceInput source="customerChatId" reference="telegram-chats">
          <AutocompleteInput fullWidth />
        </ReferenceInput>
        <ReferenceInput source="workerChatId" reference="telegram-chats">
          <AutocompleteInput fullWidth />
        </ReferenceInput>
        <ArrayInput source="chatUsers">
          <SimpleFormIterator
            disableClear={true}
            disableReordering={true}
            inline
          >
            <TextInput source="username" />
            <TextInput source="fakeUsername" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default ChatsConnectionAdd;
