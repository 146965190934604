import React from "react";
import {
    Datagrid,
    List,
    TextField
} from "react-admin";

const MessageFilterList = () => {
    return (
        <List>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="regex" />
                <TextField source="chatGptRequest" />
            </Datagrid>
        </List>
    );
};

export default MessageFilterList;