import React from "react";
import {
  Datagrid,
  List, ReferenceField, TextField
} from "react-admin";

const ChatsConnectionList = () => {
  return (
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceField source="customerChatId" reference="telegram-chats" />
        <ReferenceField source="workerChatId" reference="telegram-chats" />
      </Datagrid>
    </List>
  );
};

export default ChatsConnectionList;