import { useRef } from "react";
import { useDataProvider } from "react-admin";
import { useMutation, useQueryClient } from "react-query";

const useUpdateConfig = (resource, params, options) => {
  const dataProvider = useDataProvider();
  const queryClient = useQueryClient();
  const { meta } = params;
  const { mutationMode = "pessimistic", ...reactMutationOptions } = options;
  const mode = useRef(mutationMode);
  const paramsRef = useRef(params);
  const snapshot = useRef([]);

  const updateCache = ({ resource, data }) => {
    // hack: only way to tell react-query not to fetch this query for the next 5 seconds
    // because setQueryData doesn't accept a stale time option
    const updatedAt = Date.now();

    queryClient.setQueryData(
      [resource, "getConfig", { meta }],
      (record) => ({ ...record, ...data }),
      { updatedAt }
    );
  };


  const mutation = useMutation(
    // @ts-ignore
    (mutationParams = {}) =>{
      return dataProvider
        // @ts-ignore
        .updateConfig(mutationParams.resource, {
          // @ts-ignore
          data: mutationParams.data,
          // @ts-ignore
          previousData: mutationParams.previousData,
          // @ts-ignore
          meta: mutationParams.meta,
        })
        .then(({ data }) => data)
    },
    {
      ...reactMutationOptions,
      onMutate: async (variables) => {
        if (reactMutationOptions.onMutate) {
          const userContext =
            (await reactMutationOptions.onMutate(variables)) || {};
          return {
            snapshot: snapshot.current,
            // @ts-ignore
            ...userContext,
          };
        } else {
          // Return a context object with the snapshot value
          return { snapshot: snapshot.current };
        }
      },
      onError: (error, variables, context) => {
        // @ts-ignore
        variables = variables ?? {};
        if (mode.current === "optimistic") {
          // If the mutation fails, use the context returned from onMutate to rollback
          // @ts-ignore
          context.snapshot.forEach(([key, value]) => {
            queryClient.setQueryData(key, value);
          });
        }

        if (reactMutationOptions.onError) {
          return reactMutationOptions.onError(error, variables, context);
        }
        // call-time error callback is executed by react-query
      },
      onSuccess: (data, variables, context) => {
        // @ts-ignore
        variables = variables ?? {};
        if (mode.current === "pessimistic") {
          // update the getOne and getList query cache with the new result
          updateCache({
            // @ts-ignore
            resource: variables.resource,
            data,
          });

          if (reactMutationOptions.onSuccess) {
            reactMutationOptions.onSuccess(data, variables, context);
          }
          // call-time success callback is executed by react-query
        }
      },
      onSettled: (data, error, variables, context) => {
        // @ts-ignore
        variables = variables ?? {};
        if (mode.current === "optimistic") {
          // Always refetch after error or success:
          context.snapshot.forEach(([key]) => {
            queryClient.invalidateQueries(key);
          });
        }

        if (reactMutationOptions.onSettled) {
          return reactMutationOptions.onSettled(
            data,
            error,
            variables,
            context
          );
        }
      },
    }
  );

  const update = async (
    callTimeResource = resource,
    callTimeParams = {},
    updateOptions = {}
  ) => {
    const { mutationMode, returnPromise, onSuccess, onSettled, onError } =
      updateOptions;

    // store the hook time params *at the moment of the call*
    // because they may change afterwards, which would break the undoable mode
    // as the previousData would be overwritten by the optimistic update
    paramsRef.current = params;

    if (mutationMode) {
      mode.current = mutationMode;
    }

    if (returnPromise && mode.current !== "pessimistic") {
      console.warn(
        "The returnPromise parameter can only be used if the mutationMode is set to pessimistic"
      );
    }

    if (mode.current === "pessimistic") {
      if (returnPromise) {
        return mutation.mutateAsync(
          // @ts-ignore
          { resource: callTimeResource, ...callTimeParams },
          { onSuccess, onSettled, onError }
        );
      }
      return mutation.mutate(
        // @ts-ignore
        { resource: callTimeResource, ...callTimeParams },
        { onSuccess, onSettled, onError }
      );
    }

    // optimistic update as documented in https://react-query-v3.tanstack.com/guides/optimistic-updates
    // except we do it in a mutate wrapper instead of the onMutate callback
    // to have access to success side effects

    // @ts-ignore
    const previousRecord = queryClient.getQueryData([
      callTimeResource,
      "getConfig",
      { meta: callTimeParams.meta },
    ]);

    const queryKeys = [
      [callTimeResource, "getConfig", { meta: callTimeParams.meta }],
    ];

    /**
     * Snapshot the previous values via queryClient.getQueriesData()
     *
     * The snapshotData ref will contain an array of tuples [query key, associated data]
     *
     * @example
     * [
     *   [['posts', 'getOne', { id: '1' }], { id: 1, title: 'Hello' }],
     *   [['posts', 'getList'], { data: [{ id: 1, title: 'Hello' }], total: 1 }],
     *   [['posts', 'getMany'], [{ id: 1, title: 'Hello' }]],
     * ]
     *
     * @see https://react-query-v3.tanstack.com/reference/QueryClient#queryclientgetqueriesdata
     */
    // @ts-ignore
    snapshot.current = queryKeys.reduce(
      (prev, curr) => prev.concat(queryClient.getQueriesData(curr)),
      []
    );

    // Cancel any outgoing re-fetches (so they don't overwrite our optimistic update)
    await Promise.all(
      // @ts-ignore
      snapshot.current.map(([key]) => queryClient.cancelQueries(key))
    );

    // Optimistically update to the new value
    updateCache({
      resource: callTimeResource,
      data: callTimeParams.data,
    });

    // run the success callbacks during the next tick
    if (onSuccess) {
      setTimeout(
        () =>
          onSuccess(
            // @ts-ignore
            { ...previousRecord, ...callTimeParams.data },
            { resource: callTimeResource, ...callTimeParams },
            { snapshot: snapshot.current }
          ),
        0
      );
    }
    if (reactMutationOptions.onSuccess) {
      setTimeout(
        () =>
          reactMutationOptions.onSuccess(
            // @ts-ignore
            { ...previousRecord, ...callTimeParams.data },
            { resource: callTimeResource, ...callTimeParams },
            { snapshot: snapshot.current }
          ),
        0
      );
    }

    if (mode.current === "optimistic") {
      // call the mutate method without success side effects
      return mutation.mutate(
        // @ts-ignore
        { resource: callTimeResource, ...callTimeParams },
        { onSettled, onError }
      );
    }
  };

  return [update, mutation];
};

export default useUpdateConfig;
