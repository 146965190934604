import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import russianMessages from "ra-language-russian";

const newEnglishMessages = {
  ...englishMessages,
  usermenu: {
    login: "Log in",
  },
  ra: {
    ...englishMessages.ra,
    configurable: {
      customize: "Customize",
    },
  },
  login: {
    login: "Login",
    signIn: "Sign in",
    signUp: "Sign up",
    registrationInvitation: "Don’t have an account?",
    signInError: "Wrong login or password",
    form: {
      email: {
        label: "Email",

        email: "Invalid email",
        maxLength: "The email must be shorter than 60 character.",
      },
      password: {
        label: "Password",
        errors: {
          regex:
            "Password must be entered a letter and numbers and be longer than 8 characters and shorter than 16.",
        },
      },
    },
  },
  resources: {
    users: {
      name: "User |||| Users",
      fields: {
        password: "Password",
        role: "Role",
      },
    },
    projects: {
      name: "Project |||| Projects",
      fields: {
        preview: "Preview",
        title: "Title",
        description: "Description",
      },
    },
    contacts: {
      name: "Contact |||| Contacts",
      fields: {
        preview: "Preview",
        name: "Name",
        url: "Url",
      },
    },
    advantages: {
      name: "Advantage |||| Advantages",
      fields: {
        preview: "Preview",
        title: "Title",
        description: "Description",
      },
    },
    partners: {
      name: "Partner |||| Partners",
      fields: {
        preview: "Preview",
        name: "Name",
        url: "Url",
      },
    },
    "main-page": {
      name: "Main page settings",
      fields: {
        name: "Name",
        description: "Description",
      },
    },
    "contacts-page": {
      name: "Contacts page setup",
      fields: {
        name: "Name",
        description: "Description",
      },
    },
    "about-page": {
      name: "About page setup",
      fields: {
        name: "Name",
        description: "Description",
      },
    },
    "telegram-chats-synchronization": {
      name: "Chats synchronization",
      fields: {
        customerChatId: "Customer chat",
        workerChatId: "Worker chat",
      },
    },
    "categories": {
      name: "Tags",
      fields: {
        name: "Name",
      },
    },
    "message-filters": {
      name: "Message filters",
      fields: {
        regex: "Regex",
        chatGptRequest: "Chat GPT request",
      }
    }
  },
  tabs: {
    mainPage: {
      name: "Main page setup",
      fields: {
        name: "Name",
        description: "Description",
      },
    },
    "contacts-page": {
      name: "Contacts page setup",
      fields: {
        name: "Name",
        description: "Description",
      },
    },
    "about-page": {
      name: "About page setup",
      fields: {
        name: "Name",
        description: "Description",
      },
    },
  }
};

const newRussianMessages = {
  ...russianMessages,
  usermenu: {
    login: "Войти",
  },
  ra: {
    ...russianMessages.ra,
    configurable: {
      customize: "Кастомизировать",
    },
    page: {
      ...russianMessages.ra.page,
      empty: "Нет записей в таблице \"%{name}\"",
    }
  },
  login: {
    login: "Вход",
    signIn: "Вход",
    signUp: "Регистрация",
    registrationInvitation: "Еще не зарегестрированы?",
    signInError: "Неверный логин или пароль",
    form: {
      email: {
        label: "Email",
        errors: {
          email: "Неправильный email",
          maxLength: "Email должен быть короче 60 символов",
        },
      },
      password: {
        label: "Пароль",
        errors: {
          regex:
            "Пароль должен состоять из цифр и букв, быть длинее 8 символов и короче 16",
        },
      },
    },
  },
  resources: {
    users: {
      name: "Пользователь |||| Пользователи",
      fields: {
        password: "Пароль",
        role: "Роль",
      },
    },
    projects: {
      name: "Проект |||| Проекты",
      fields: {
        preview: "Превью",
        title: "Название",
        description: "Описание",
      },
    },
    contacts: {
      name: "Контакт |||| Контакты",
      fields: {
        preview: "Превью",
        name: "Название",
        url: "Url",
      },
    },
    advantages: {
      name: "Преимущества |||| Преимущества",
      fields: {
        preview: "Превью",
        title: "Название",
        description: "Description",
      },
    },
    partners: {
      name: "Партнер |||| Партнеры",
      fields: {
        preview: "Превью",
        name: "Название",
        url: "Url",
      },
    },
    "main-page": {
      name: "Настройки главной",
      fields: {
        name: "Название",
        description: "Описание",
      },
    },
    "contacts-page": {
      name: "Настройка контактов",
      fields: {
        name: "Название",
        description: "Описание",
      },
    },
    "about-page": {
      name: "Настройка описания",
      fields: {
        name: "Название",
        description: "Описание",
      },
    },
    "telegram-chats-synchronization": {
      name: "Синхронизация чатов",
      fields: {
        customerChatId: "Чат заказчика",
        workerChatId: "Чат исполнителя",
      },
    },
    "categories": {
      name: "Теги",
      fields: {
        name: "Название",
      }
    },
    "message-filters": {
      name: "Фильтры сообщений",
      fields: {
        regex: "Регулярное выражение",
        chatGptRequest: "Запрос Chat GPT",
      }
    }
  }
};

export const i18nProvider = polyglotI18nProvider(
  // @ts-ignore
  (locale) => (locale === "ru" ? newRussianMessages : newEnglishMessages),
  "en" // Default locale
);
