import * as React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  Edit,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
} from "react-admin";

const ChatsConnectionEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm style={{ display: "flex" }}>
        <ReferenceInput
          source="customerChatId"
          reference="telegram-chats"
          filter={{ unused: true }}
        >
          <AutocompleteInput fullWidth />
        </ReferenceInput>
        <ReferenceInput
          source="workerChatId"
          reference="telegram-chats"
          filter={{ unused: true }}
        >
          <AutocompleteInput fullWidth />
        </ReferenceInput>
        <ArrayInput source="chatUsers">
          <SimpleFormIterator
            disableClear={true}
            disableReordering={true}
            inline
          >
            <TextInput source="username" />
            <TextInput source="fakeUsername" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default ChatsConnectionEdit;
