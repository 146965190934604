import React from "react";
import {
  Admin,
  AppBar,
  CustomRoutes,
  Layout, LocalesMenuButton,
  Logout,
  Menu,
  Resource, UserMenu
} from "react-admin";

import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DescriptionIcon from "@mui/icons-material/Description";
import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ChatIcon from '@mui/icons-material/Chat';
import {
  Typography
} from "@mui/material";
import { Route } from "react-router-dom";
import "./App.css";
import authProvider from "./authProvider";
import { dataProvider } from "./dataProvider";
import { i18nProvider } from "./i18nProvider";
import AboutPageConfigEdit from "./pages/AboutPageConfigEdit/AboutPageConfigEdit";
import ContactPageConfigEdit from "./pages/ContactPageConfigEdit/ContactsPageConfigEdit";
import MainPageSetupEdit from "./pages/MainPageConfigEdit/MainPageConfigEdit";
import AdvantagesAdd from "./pages/advantages/AdvantagesAdd/AdvantagesAdd";
import AdvantagesEdit from "./pages/advantages/AdvantagesEdit/AdvantagesEdit";
import ChatsConnectionAdd from "./pages/chats-connection/chats-connection-add/ChatsConnectionAdd";
import ChatsConnectionEdit from "./pages/chats-connection/chats-connection-edit/ChatsConnectionEdit";
import ChatsConnectionList from "./pages/chats-connection/chats-connection-list/ChatsConnectionList";
import ContactsAdd from "./pages/contacts/ContactsAdd/ContactsAdd";
import ContactsEdit from "./pages/contacts/ContactsEdit/ContactsEdit";
import Login from "./pages/login/Login";
import PartnersAdd from "./pages/partners/PartnersAdd/PartnersAdd";
import PartnersEdit from "./pages/partners/PartnersEdit/PartnersEdit";
import ProjectsAdd from "./pages/projects/ProjectsAdd/ProjectsAdd";
import ProjectsEdit from "./pages/projects/ProjectsEdit/ProjectsEdit";
import ProjectsList from "./pages/projects/ProjectsList/ProjectList";
import UserAdd from "./pages/users/UserAdd/UserAdd";
import UserEdit from "./pages/users/UserEdit/UserEdit";
import UsersList from "./pages/users/UsersList/UsersList";
import MessageFilterAdd from "./pages/message-filtes/MessageFilterAdd/MessageFilterAdd";
import MessageFilterEdit from "./pages/message-filtes/MessageFilterEdit/MessageFilterEdit";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CategoryAdd from "./pages/categories/CategoryAdd/CategoryAdd";
import CategoryEdit from "./pages/categories/CategoryEdit/CategoryEdit";
import CategoryList from "./pages/categories/CategoryList/CategoryList";
import TagIcon from '@mui/icons-material/Tag';
import MessageFilterList from "./pages/message-filtes/MessageFilterList/MessageFilterList";

const MyAppBar = (props) => (
  <AppBar {...props} userMenu={<CustomUserMenu />}>
    <Typography flex="1" variant="h6" id="react-admin-title"></Typography>
    <LocalesMenuButton
      languages={[
        { locale: "en", name: "English" },
        { locale: "ru", name: "Russian" },
      ]}
    />
  </AppBar>
);

const CustomUserMenu = () => {
  return (
    <UserMenu>
      <Logout />
    </UserMenu>
  );
};

export const MyMenu = () => {
  return (
    <Menu>
      <Menu.Item
        to="/main-page-setup"
        primaryText="resources.main-page.name"
        leftIcon={<HomeIcon />}
      />
      <Menu.Item
        to="/contacts-page-setup"
        primaryText="resources.contacts-page.name"
        leftIcon={<GroupIcon />}
      />
      <Menu.Item
        to="/about-page-setup"
        primaryText="resources.about-page.name"
        leftIcon={<DescriptionIcon />}
      />
      <Menu.ResourceItem key="projects" name="projects" />
      <Menu.ResourceItem key="categories" name="categories" />
      <Menu.ResourceItem key="users" name="users" />
      <Menu.ResourceItem key="telegram-chats-synchronization" name="telegram-chats-synchronization" />
      <Menu.ResourceItem key="message-filters" name="message-filters" />
    </Menu>
  );
};

const MyLayout = (props) => (
  <Layout {...props} appBar={MyAppBar} menu={MyMenu} />
);

function App() {
  return (
    <Admin
      loginPage={Login}
      dataProvider={dataProvider}
      // @ts-ignore
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      layout={MyLayout}
    >
      <Resource
        name="advantages"
        create={AdvantagesAdd}
        edit={AdvantagesEdit}
        icon={TaskAltIcon}
      />
      <Resource
        name="partners"
        create={PartnersAdd}
        edit={PartnersEdit}
        icon={GroupIcon}
      />
      <Resource
        name="contacts"
        create={ContactsAdd}
        edit={ContactsEdit}
        icon={GroupIcon}
      />
      <Resource
        name="projects"
        create={ProjectsAdd}
        edit={ProjectsEdit}
        list={ProjectsList}
        icon={AccountTreeIcon}
      />
      <Resource
        name="categories"
        create={CategoryAdd}
        edit={CategoryEdit}
        list={CategoryList}
        icon={TagIcon}
      />
      <Resource
        name="users"
        create={UserAdd}
        edit={UserEdit}
        list={UsersList}
        icon={PersonIcon}
      />
      <Resource
        name="telegram-chats-synchronization"
        create={ChatsConnectionAdd}
        edit={ChatsConnectionEdit}
        list={ChatsConnectionList}
        icon={ChatIcon}
      />
      <Resource
        name="telegram-chats"
        recordRepresentation={item => `${item.name}`}
      />
      <Resource
        name="message-filters"
        create={MessageFilterAdd}
        edit={MessageFilterEdit}
        list={MessageFilterList}
        icon={FilterAltIcon}
      />
      <CustomRoutes>
        <Route path="/main-page-setup" element={<MainPageSetupEdit />} />
        <Route
          path="/contacts-page-setup"
          element={<ContactPageConfigEdit />}
        />
        <Route path="/about-page-setup" element={<AboutPageConfigEdit />} />
      </CustomRoutes>
    </Admin>
  );
}

export default App;
