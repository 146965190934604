import * as React from "react";
import {
  Create,
  PasswordInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

const UserAdd = (props) => {
  return (
    <Create {...props}>
      <SimpleForm style={{ display: "flex" }}>
        <TextInput fullWidth source="email" />
        <SelectInput
          fullWidth
          source="role"
          choices={[{ id: "ROLE_ADMINISTRATOR", name: "Administrator" }]}
        />
        <PasswordInput fullWidth source="password" />
      </SimpleForm>
    </Create>
  );
};

export default UserAdd;
