import React from "react";
import { EditView, useCheckMinimumRequiredProps } from "react-admin";
import { EditConfigBase } from "./EditConfigBase";

export const EditConfig = (
  props
) => {
  useCheckMinimumRequiredProps('Edit', ['children'], props);
  const {
      resource,
      id,
      mutationMode,
      mutationOptions,
      queryOptions,
      redirect,
      transform,
      disableAuthentication,
      ...rest
  } = props;
  return (
      <EditConfigBase
          resource={resource}
          id={id}
          mutationMode={mutationMode}
          mutationOptions={mutationOptions}
          queryOptions={queryOptions}
          redirect={redirect}
          transform={transform}
          disableAuthentication={disableAuthentication}
      >
          <EditView {...rest} />
      </EditConfigBase>
  );
};