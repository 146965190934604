import React from "react";
import {
  Datagrid,
  ImageField,
  List, TextField,
  useLocaleState
} from "react-admin";

const ProjectsList = () => {
  const [locale] = useLocaleState();

  return (
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ImageField source="picture.src" />
        <TextField source={`title.${locale}`} />
      </Datagrid>
    </List>
  );
};

export default ProjectsList;