import React from "react";
import { EditContextProvider, ResourceContextProvider } from "react-admin";
import { useEditConfigController } from "../../hooks/useEditConfigCotroller";

export const EditConfigBase = ({
  children,
  ...props
}) => {
  const controllerProps = useEditConfigController(props);
  const body = (
      <EditContextProvider value={controllerProps}>
          {children}
      </EditContextProvider>
  );
  return props.resource ? (
      // support resource override via props
      <ResourceContextProvider value={props.resource}>
          {body}
      </ResourceContextProvider>
  ) : (
      body
  );
};