import ImageIcon from "@mui/icons-material/Image";
import { ToggleButton } from "@mui/material";
import {
  AlignmentButtons,
  ClearButtons,
  FormatButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
  RichTextInput,
  RichTextInputToolbar,
  useTiptapEditor,
} from "ra-input-rich-text";
import * as React from "react";
import {
  Create,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
  TranslatableInputs,
  useTranslate,
} from "react-admin";

const MyImageButton = (props) => {
  const translate = useTranslate();
  const editor = useTiptapEditor();

  const label = translate("ra.tiptap.image", { _: "Image" });

  const addImage = React.useCallback(() => {
    const url = window.prompt(
      translate("ra.tiptap.image_dialog", { _: "Image URL" })
    );

    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  }, [editor, translate]);

  return editor ? (
    <ToggleButton
      aria-label={label}
      title={label}
      {...props}
      disabled={!editor?.isEditable}
      value="image"
      onClick={addImage}
    >
      <ImageIcon fontSize="inherit" />
    </ToggleButton>
  ) : null;
};

const AdvantagesAdd = (props) => {
  return (
    <Create {...props} mutationOptions={{ meta: { fileUpload: true } }}>
      <SimpleForm style={{ display: "flex" }}>
        <ImageInput source="picture" label="Preview">
          <ImageField source="src" title="title" />
        </ImageInput>
        <TranslatableInputs className="fullWidth" locales={["en", "ru"]}>
          <TextInput fullWidth source="title" />
          <RichTextInput
            fullWidth
            source="description"
            onDragEnter={(data) => {
              console.log("33");
              console.log(data);
            }}
            toolbar={
              <RichTextInputToolbar>
                <LevelSelect />
                <FormatButtons />
                <AlignmentButtons />
                <ListButtons />
                <LinkButtons />
                <QuoteButtons />
                <ClearButtons />
                <MyImageButton />
              </RichTextInputToolbar>
            }
          />
        </TranslatableInputs>
      </SimpleForm>
    </Create>
  );
};

export default AdvantagesAdd;
