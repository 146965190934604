import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: `${window["env"]["useSsl"] ? "https" : "http"}://${window["env"]["apiDomain"]}`,
  paramsSerializer: {
    indexes: null, // by default: false
  },
});

axiosInstance.interceptors.request.use((request) => {
  let token = localStorage.getItem("token");
  if (token && request.headers) {
    request.headers["Authorization"] = "Bearer " + token;
  }

  return request;
});

const extractFiles = (object) => {
  let files = [];
  for (let key of Object.keys(object)) {
    if (key === "rawFile" && object[key] instanceof File) {
      files.push(object[key]);
    } else if (object[key] instanceof Object) {
      files.push(...extractFiles(object[key]));
    }
  }

  return files;
};

export const dataProvider = {
  getList: (resource, params) => {
    let requestParams = {
      sortBy: params.sort.field,
      sortDirection: params.sort.order,
      pageNumber: params.pagination.page,
      pageSize: params.pagination.perPage,
      ...params.filter,
    };
    return axiosInstance
      .get(`/api/v1/${resource}`, { params: requestParams })
      .then((response) => {
        return response.data;
      });
  },

  getConfig: (resource, params) => {
    let url = `/api/v1/${resource}`

    return axiosInstance
      .get(url)
      .then((response) => {
        return { data: response.data };
      });
  },

  getOne: (resource, params) => {
    let url = `/api/v1/${resource}/${params.id}`

    return axiosInstance
      .get(url)
      .then((response) => {
        return { data: response.data };
      });
  },

  getMany: (resource, params) => {
    return axiosInstance
      .get(`/api/v1/${resource}?ids=${params.ids}`)
      .then((response) => {
        return { data: response.data };
      });
  },

  getManyReference: (resource, params) => {
    let requestParams = {
      sortBy: params.sort.field,
      sortDirection: params.sort.order,
      pageNumber: params.pagination.page,
      pageSize: params.pagination.perPage,
      ...params.filter,
    };
    requestParams[params.target] = params.id;
    return axiosInstance
      .get(`/api/v1/${resource}`, { params: requestParams })
      .then((response) => {
        return response.data;
      });
  },

  create: (resource, params) => {

    if (params?.meta?.fileUpload) {
      console.log(params);
      let files = extractFiles(params.data);

      const form = new FormData();
      let blob = new Blob([JSON.stringify(params.data)], {
        type: "application/json",
      });

      form.append("request", blob);
      files.forEach((file) => {
        form.append("files", file);
      });
      console.log(JSON.stringify(params.data));
      return axiosInstance
        .post(`/api/v1/${resource}`, form)
        .then((response) => {
          return { data: response.data };
        });
    }

    return axiosInstance
      .post(`/api/v1/${resource}`, params.data)
      .then((response) => {
        return { data: response.data };
      });
  },

  updateConfig: (resource, params) => {
    if (params?.meta?.fileUpload) {
      let files = extractFiles(params.data);

      const form = new FormData();
      let blob = new Blob([JSON.stringify(params.data)], {
        type: "application/json",
      });

      form.append("request", blob);
      files.forEach((file) => {
        form.append("files", file);
      });
      console.log(JSON.stringify(params.data));
      return axiosInstance
        .put(`/api/v1/${resource}`, form)
        .then((response) => {
          return { data: response.data };
        });
    }

    return axiosInstance
      .put(`/api/v1/${resource}`, params.data)
      .then((response) => {
        return { data: response.data };
      });
  },

  update: (resource, params) => {
    if (params?.meta?.fileUpload) {
      console.log(params);
      let files = extractFiles(params.data);

      const form = new FormData();
      let blob = new Blob([JSON.stringify(params.data)], {
        type: "application/json",
      });

      form.append("request", blob);
      files.forEach((file) => {
        form.append("files", file);
      });
      console.log(JSON.stringify(params.data));
      return axiosInstance
        .put(`/api/v1/${resource}/${params.id}`, form)
        .then((response) => {
          return { data: response.data };
        });
    }

    return axiosInstance
      .put(`/api/v1/${resource}/${params.id}`, params.data)
      .then((response) => {
        return { data: response.data };
      });
  },

  updateMany: (resource, params) => {
    return Promise.resolve({});
  },

  delete: (resource, params) => {
    return axiosInstance
      .delete(`/api/v1/${resource}/${params.id}`)
      .then((response) => {
        return { data: params.data };
      });
  },

  deleteMany: (resource, params) => {
    return axiosInstance
      .delete(`/api/v1/${resource}?ids=${params.ids}`)
      .then((response) => {
        return { data: params.ids };
      });
  },
};
