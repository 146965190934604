import * as React from "react";
import {
    Edit,
    SimpleForm,
    TextInput
} from "react-admin";

const MessageFilterEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm style={{ display: "flex", width: "100%" }}>
                <TextInput source="regex" fullWidth />
                <TextInput source="chatGptRequest" fullWidth />
            </SimpleForm>
        </Edit>
    );
};

export default MessageFilterEdit;