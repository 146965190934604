import * as React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    TranslatableInputs
} from "react-admin";

const CategoryEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm style={{ display: "flex", width: "100%" }}>
                <TranslatableInputs className="fullWidth" locales={["en", "ru"]}>
                    <TextInput source="name" fullWidth/>
                </TranslatableInputs>
            </SimpleForm>
        </Edit>
    );
};

export default CategoryEdit;